<template>
  <div class="d-flex flex-column justify-content-center h-100">
    <div class="pb-3">
      <img src="@/assets/images/404.png">
    </div>
    <h2>{{ $t("maintenancePage.title") }}</h2>
    <p>{{ $t("maintenancePage.message") }}</p>
  </div>
</template>

<script>
export default {
  name: 'MaintenancePage',
  created () {
    if (this.$store.getters.generalSettings.interface) { // Redirect to homepage
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>
